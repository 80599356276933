import React from "react";

const DailyScript = () => {
    return (
        <div style={{width: '150dvh', height: '100dvh' }}>
            <embed src="/dailyScript" width="100%" height="100%" />
        </div>
    );
};

export default DailyScript;