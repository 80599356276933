import React from "react";

const AnniversaryScript = () => {
    return (
        <div style={{width: '150dvh', height: '100dvh' }}>
            <embed src="/anniversaryScript" width="100%" height="100%" />
        </div>
    );
};

export default AnniversaryScript;