import React from "react";
import theme from "../utils/theme";

const PageNotFound = () => {
    return (
        <div style={theme.contentContainer}>
            <h2>Page Not Found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default PageNotFound;